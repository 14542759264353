:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._bg-light{background-color:var(--light);}
:root ._pr-t-space-4{padding-right:var(--t-space-4);}
:root ._pl-t-space-4{padding-left:var(--t-space-4);}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._mb-t-space-4{margin-bottom:var(--t-space-4);}
:root ._gap-t-space-4{gap:var(--t-space-4);}
:root ._fd-row{flex-direction:row;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._ai-flex-start{align-items:flex-start;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._jc-center{justify-content:center;}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._pt-t-space-2{padding-top:var(--t-space-2);}
:root ._pb-t-space-2{padding-bottom:var(--t-space-2);}
:root ._bg-lighter{background-color:var(--lighter);}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._bxsh-0px2px5pxva26674076{box-shadow:0px 2px 5px var(--shadowColor);}
:root ._gap-t-space-2{gap:var(--t-space-2);}